<template>
  <div>
    <div align="center" class="my-5" v-if="dati_vuoti == ''">
      <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      variant="primary"
      />
    </div>

    <section id="dashboard-ecommerce" class="mb-5" v-if="dati_vuoti != ''">
      <b-row>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <h3 class="mb-2">{{ data.scheda.titolo }}</h3>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <span class="text-muted">ubicato in</span><br />
                  <h6 class="mb-0">{{ data.scheda.via }}<br />{{ data.scheda.comune }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <span class="text-muted">dati aggornati al</span><br />
                  <h6 class="mb-0">{{ data.scheda.datains }}</h6>
                </b-media-body>
              </b-media>
              <div v-if="data.scheda.note != ''">
                <hr />
                <small class="text-muted">note: </small><br />{{ data.scheda.note }}
              </div>

            </b-card-body>
          </b-card>

          <div class="d-flex flex-row bd-highlight pb-2">
            <b-button
            variant="outline-primary"
            @click="tornaDettaglioRichiesta(data.scheda.id_lead, data.scheda.id_azienda)"
            class="mr-1 flex-md-grow-1"
            >Torna alla Richiesta
            </b-button>

          </div>
        </b-col>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <h3 class="mb-2">Azienda</h3>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-top">
                <div class="meetup-day">
                  <span class="text-muted">codice cliente:</span><br />
                  <h5 class="mb-0">
                    {{ data.azienda.codice }}
                  </h5>
                </div>
                <div class="my-auto">
                  <h3 class="mb-25">
                    {{ data.azienda.rag_soc }}
                  </h3>
                  <b-card-text class="mb-25">
                    p.iva: {{ data.azienda.piva }}
                  </b-card-text>
                </div>
              </div>
              
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.indirizzo }}<br />
                    {{ data.azienda.cap }} - {{ data.azienda.localita }} ({{ data.azienda.provincia }}) - {{ data.azienda.paese }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.telefono }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.email }}</h6>
                </b-media-body>
              </b-media>

            </b-card-body>

          </b-card>
        </b-col>
      </b-row>

      <b-card bg-variant="Default"
        v-for="(riga,index) in data.scheda_righe"
        :key="index"
      >
        <h4 v-if="riga.step_titolo">{{ riga.step_titolo }}</h4>

        <div class="table-responsive-md">
          <table class="table table-striped">
            <tbody>
              <tr v-for="(row,indice) in riga.campi"
              :key="indice">
                <td class="font-italic">
                  {{ row.nome }}                 
                </td>
                <td v-if="row.tipo_campo == 'text'">{{ row.valore }}</td>
                <td v-if="row.tipo_campo == 'photo'">
                  <div class="d-flex flex-row" v-if="row.valore != ''">
                    <a :href="row.valore" target="_blank" class="flex-grow-5'">
                      <b-img-lazy :src="row.valore" fluid />
                    </a>
                    
                    <div class=" flex-md-grow-1">
                      <feather-icon
                        :id="`photo-riga-${row.id}-elimina`"
                        icon="XCircleIcon"
                        class="cursor-pointer ml-1 text-danger"
                        size="21"
                        @click="cancellaPhoto(row.id, row.id_campo)"
                      />
                      <b-tooltip
                          title="cancella foto"
                          class="cursor-pointer"
                          :target="`photo-riga-${row.id}-elimina`"
                      />
                    </div>
                  </div> 
                  <span v-else> --- </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </b-card>

    </section>

  </div>
</template>

<script>
import router from '@/router'
import { BRow, BCol, BButton, BCard, BCardText, BCardTitle, BAvatar, BCardBody, BMedia, BMediaAside, BMediaBody, BSpinner, 
BImgLazy, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard, 
    BCardText, 
    BCardTitle,
    BAvatar,
    BCardBody, 
    BMedia, 
    BMediaAside, 
    BMediaBody, 
    BSpinner, 
    BImgLazy, 
    BTooltip, 
  },
  data() {
    return {
      dati_vuoti: '',
      data: { },
      messaggio: '',
    }
  },
  created() {
    console.log('id (speriamo bene) -> '+router.currentRoute.params.id+"\nid_sopraluogo -> "+router.currentRoute.params.id_sopraluogo);
    
    this.$http.get('v2/crmadmin/infosopraluogototale/'+router.currentRoute.params.id+'/'+router.currentRoute.params.id_sopraluogo)
      .then(response => {
        this.data = response.data
        console.log(response.data)

        if(response.data.scheda === false){
          //ordine_trovato
          this.dati_vuoti = '';

          //azzera dati scheda visualizzati
          this.data = { }
        } else {          
          //ordine_trovato
          this.dati_vuoti = 'mo';

          //visualizza dati della scheda ricevuti
          this.data = response.data
        }

      }).catch(e => {
        console.log(e);
      });
  },
  methods: {
    tornaDettaglioRichiesta(id_lead, id_azienda){
      this.$router.replace('/crm/leadinfo/'+id_lead+'/'+id_azienda)
    },
    cancellaPhoto(id, id_campo){
      console.log('id -> '+id+" | id_campo -> "+id_campo)

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva della foto selezionata",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'SI, Cancellala',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            /*
            this.$http.get('v2/crmadmin/softdeletelead/'+id+'/'+id_azienda+'/'+id_utente_admin)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)
                console.log("response.data.dati.id_cancellato -> "+response.data.dati.id_cancellato)

                if((response.data.statusCode===200) && (response.data.dati.id_cancellato == id)){
                //risposta positiva
                    this.$swal({
                        icon: 'success',
                        title: 'Spostato nel cestino',
                        text: 'operazione effettuata correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })

                    //aggiorna tabella
                    this.loadItems(this.userData.id,this.idLinkSelectd);
                    
                } else {
                //risposta negativa (errore sul server)
                    this.$swal({
                        icon: 'error',
                        title: 'Si è verificato un errore',
                        text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - ERRORE DA-004',
                        customClass: {
                        confirmButton: 'btn btn-outline-primary',
                        },
                    })
                }
            }).catch(e => {
                console.log(e);
            })
            */
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
